import AdvocateVijayDetails from '../../components/about/advocates-details/vijay';
import Footer from '../../components/common/footer';
import Layout from '../../components/common/layout';
import NavigationMenu from '../../components/common/navigationMenu';
import PageHeader from '../../components/common/pageHeader';
import React from 'react';

const AdvocateVijayDetailsPage = () => {
  return (
    <Layout pageTitle='Avadhani & Associates | K. Vijay Bhaskar Reddy'>
      <NavigationMenu active='about' />
      <PageHeader
        title='K. Vijay Bhaskar Reddy'
        subMenu='Advocates'
        backLink='/about#team-section'
      />
      <AdvocateVijayDetails />
      <Footer />
    </Layout>
  );
};

export default AdvocateVijayDetailsPage;
