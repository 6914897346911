import React from 'react';
import advocateProfilePic from '../../../assets/images/advocates-details/vijay.jpg';

const AdvocateVijayDetails = () => {
  return (
    <section className='team-details'>
      <div className='container'>
        <div className='row justify-content-between'>
          <div className='col-lg-5'>
            <div className='team-details__content'>
              <h2 className='team-details__title'>Read my story</h2>
              <p className='team-details__text'>
                Mr. Vijay Bhaskar did his LLB and graduated from Osmania
                University. He also completed his Master of Laws. He enrolled as
                an Advocate in the year 1998. He has more than 20 years of
                standing. He is specialised in the areas of Civil, Criminal,
                Service matters, Arbitration laws and Writs. Presently he is
                practicing in Hon’ble High Court of Telangana and Andra Pradesh,
                National Company law Tribunal, City Civil Courts, Criminal
                Courts, Arbitrations at Hyderbad.
              </p>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={advocateProfilePic} alt='' />
              </div>
              <div className='team-one__content'>
                <h2 className='team-one__name'>
                  Mr. K Vijay Bhaskar Reddy, LLM.,
                </h2>
                <p className='team-one__designation'>
                  Partner, Sr. Consultant and Advocate
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdvocateVijayDetails;
